import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Cross = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 58.8 58.8" color="primary">
      <g>
        <path
          d="M29.4,56.5c-14.9,0-27-12.1-27-27.1s12.1-27.1,27-27.1s27,12.2,27,27.1S44.3,56.5,29.4,56.5 M29.4,0.7
			C13.5,0.7,0.7,13.6,0.7,29.4c0,15.9,12.9,28.7,28.7,28.7s28.7-12.8,28.7-28.7S45.3,0.7,29.4,0.7"
        />
        <path
          d="M29.4,56.5c-14.9,0-27-12.1-27-27.1s12.1-27.1,27-27.1s27,12.2,27,27.1S44.3,56.5,29.4,56.5z M29.4,0.7
			C13.5,0.7,0.7,13.6,0.7,29.4c0,15.9,12.9,28.7,28.7,28.7s28.7-12.8,28.7-28.7S45.3,0.7,29.4,0.7z"
        />
        <path
          d="M48,34.5H35.3c-0.5,0-0.8,0.4-0.8,0.8V48H24.3V35.3c0-0.5-0.4-0.8-0.8-0.8H10.8V24.3h12.7
			c0.5,0,0.8-0.4,0.8-0.8V10.8h10.1v12.7c0,0.5,0.4,0.8,0.8,0.8h12.7L48,34.5L48,34.5z M48.8,22.6H36.2V10c0-0.5-0.4-0.8-0.8-0.8
			H23.5c-0.5,0-0.8,0.4-0.8,0.8v12.7H10c-0.5,0-0.8,0.4-0.8,0.8v11.8c0,0.5,0.4,0.8,0.8,0.8h12.7v12.7c0,0.5,0.4,0.8,0.8,0.8h11.8
			c0.5,0,0.8-0.4,0.8-0.8V36.2h12.7c0.5,0,0.8-0.4,0.8-0.8V23.5C49.7,23,49.3,22.6,48.8,22.6"
        />
        <path
          d="M48,34.5H35.3c-0.5,0-0.8,0.4-0.8,0.8V48H24.3V35.3c0-0.5-0.4-0.8-0.8-0.8H10.8V24.3h12.7
			c0.5,0,0.8-0.4,0.8-0.8V10.8h10.1v12.7c0,0.5,0.4,0.8,0.8,0.8h12.7L48,34.5L48,34.5z M48.8,22.6H36.2V10c0-0.5-0.4-0.8-0.8-0.8
			H23.5c-0.5,0-0.8,0.4-0.8,0.8v12.7H10c-0.5,0-0.8,0.4-0.8,0.8v11.8c0,0.5,0.4,0.8,0.8,0.8h12.7v12.7c0,0.5,0.4,0.8,0.8,0.8h11.8
			c0.5,0,0.8-0.4,0.8-0.8V36.2h12.7c0.5,0,0.8-0.4,0.8-0.8V23.5C49.7,23,49.3,22.6,48.8,22.6z"
        />
      </g>
    </SvgIcon>
  );
};

Cross.displayName = 'Cross';

Cross.defaultProps = {};

export default Cross;
