import React from 'react';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useStaticQuery, graphql } from 'gatsby';

import Feature from './Feature';
import Spacer from './Spacer';
import { useCurrentLanguage } from './LanguageProvider';

const FeatureList = () => {
  const theme = useTheme();
  const { dataJson } = useStaticQuery(graphql`
    query FeatureList {
      dataJson {
        features {
          de {
            title
            text
            icon
          }
          en {
            icon
            text
            title
          }
        }
      }
    }
  `);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const currentLang = useCurrentLanguage();

  return (
    <>
      <Typography color="primary" variant="h4">
        <strong>Features</strong>
      </Typography>
      <Spacer size={4} />
      <Box
        style={{
          gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
          gridGap: theme.spacing(2),
        }}
        display="grid"
      >
        {dataJson.features[currentLang].map((feature) => {
          return <Feature key={feature.title} {...feature} />;
        })}
      </Box>
    </>
  );
};

FeatureList.displayName = 'FeatureList';

FeatureList.propTypes = {};

FeatureList.defaultProps = {};

export default FeatureList;
