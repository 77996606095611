import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Eye = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 61.3 61.3" color="primary">
      <g>
        <path
          d="M53.6,43H49v-4.6c0-0.8-0.7-1.5-1.5-1.5S46,37.6,46,38.4V43h-4.6c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H46
			v4.6c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V46h4.6c0.8,0,1.5-0.7,1.5-1.5S54.4,43,53.6,43"
        />
        <path
          d="M47.5,33.8c-5.9,0-10.7,4.8-10.7,10.7s4.8,10.7,10.7,10.7s10.7-4.8,10.7-10.7S53.4,33.8,47.5,33.8 M47.5,58.2
			c-7.6,0-13.7-6.2-13.7-13.7S40,30.8,47.5,30.8c7.6,0,13.7,6.2,13.7,13.7S55.1,58.2,47.5,58.2"
        />
        <path
          d="M60.8,25.1c-0.1-0.2-3.6-4-9-7.9c-3.2-2.3-6.4-4.1-9.6-5.4c-0.8-0.3-1.6-0.6-2.4-0.9c-0.1,0-0.1,0-0.2-0.1
	c-3.1-1-6.1-1.5-9-1.5s-5.9,0.5-9,1.5c-0.1,0-0.1,0-0.2,0.1c-0.8,0.3-1.6,0.6-2.4,0.9c-3.1,1.3-6.4,3.1-9.6,5.4
	c-5.4,3.9-8.9,7.7-9,7.9c-0.5,0.6-0.5,1.5,0,2c0.1,0.1,3.4,3.7,8.5,7.5c3,2.2,6,4,9,5.3c3.8,1.7,7.5,2.7,11,2.9H29
	c0.8,0,1.5-0.6,1.5-1.4c0.1-0.8-0.6-1.6-1.4-1.6c-7.2-0.5-14-4.4-18.4-7.6c-3.3-2.4-5.8-4.8-7.1-6.1c1.4-1.4,4.1-3.9,7.6-6.4
	c1.6-1.2,3.5-2.4,5.7-3.5c-1.1,2.1-1.6,4.4-1.6,6.9c0,3.8,1.4,7.4,3.9,10.2s5.9,4.5,9.7,5c0.1,0,0.1,0,0.2,0c0.8,0,1.4-0.6,1.5-1.4
	c0.1-0.8-0.5-1.6-1.3-1.7c-6.2-0.7-10.9-5.9-10.9-12.1c0-3.6,1.6-7,4.4-9.3c2.5-0.8,5.2-1.3,7.9-1.3s5.3,0.5,7.9,1.3
	c2.8,2.3,4.4,5.7,4.4,9.3c0,0.9-0.1,1.8-0.3,2.7c-0.2,0.8,0.3,1.6,1.1,1.8c0.1,0,0.2,0,0.3,0c0.7,0,1.3-0.5,1.5-1.2
	c0.3-1.1,0.4-2.2,0.4-3.4c0-2.4-0.6-4.8-1.6-6.9c2.1,1.1,4,2.3,5.7,3.5c3.5,2.5,6.2,5.1,7.6,6.4c-0.5,0.5-1.2,1.1-2,1.9
	c-0.6,0.6-0.7,1.5-0.1,2.2c0.6,0.6,1.5,0.7,2.2,0.1c2-1.8,3.1-3.1,3.2-3.1C61.4,26.6,61.4,25.7,60.8,25.1L60.8,25.1z"
        />
      </g>
    </SvgIcon>
  );
};

Eye.displayName = 'Eye';

Eye.propTypes = {};

Eye.defaultProps = {};

export default Eye;
