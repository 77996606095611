import Eye from './Eye';
import Brain from './Brain';
import Building from './Building';
import Cross from './Cross';
import Megaphone from './Megaphone';
import Persons from './Persons';
import Touchless from './Touchless';

export default {
  Eye,
  Brain,
  Building,
  Cross,
  Megaphone,
  Persons,
  Touchless,
};
