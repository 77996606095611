import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  Typography as MuiTypo,
  Box,
  Container,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';

import { useTrail, animated, config } from 'react-spring';

import Image from 'gatsby-image';

import Spacer from './Spacer';
import { useCurrentLanguage } from './LanguageProvider';

const Typography = animated(MuiTypo);

const useStyles = makeStyles((theme) => ({
  mark: {
    boxDecorationBreak: 'clone',
  },
}));

const Hero = () => {
  const theme = useTheme();
  const cls = useStyles();

  const { image_german, image_english, dataJson } = useStaticQuery(graphql`
    query Hero {
      dataJson {
        hero {
          de {
            title
            subtitle
          }
          en {
            title
            subtitle
          }
        }
      }
      image_german: file(
        relativePath: { eq: "Website-Header-Infodevice-German-SomniaRatio.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 760, fit: CONTAIN) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image_english: file(
        relativePath: {
          eq: "Website-Header-Infodevice-English-SomniaRatio.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 760, fit: CONTAIN) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const currentLang = useCurrentLanguage();

  const image = currentLang === 'de' ? image_german : image_english;

  const typos = [
    {
      text: dataJson.hero[currentLang].title,
      variant: isMobile ? 'h4' : 'h3',
      mark: {
        style: {
          background: 'linear-gradient(120deg, #50bed4, #1e969f)',
          color: 'white',
          padding: `0px ${theme.spacing(1)}px`,
        },
      },
    },
    {
      text: dataJson.hero[currentLang].subtitle,
      variant: isMobile ? 'h5' : 'h4',
      mark: {
        style: {
          background: `#FFF`,
          padding: `0px ${theme.spacing(1)}px`,
        },
      },
    },
  ];

  const trails = useTrail(2, {
    opacity: 1,
    from: { opacity: 0 },
    config: config.molasses,
  });

  return (
    <Box width="100vw" height="100vh">
      <Image
        style={{ height: '95%', marginTop: '5%' }}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'bottom right',
        }}
        fluid={image.childImageSharp.fluid}
      />
      <Box position="absolute" bottom="10%" width="100%">
        <Container maxWidth="md">
          {trails.map((props, index) => {
            const { variant, mark, text } = typos[index];
            return (
              <React.Fragment key={text}>
                <Typography style={props} variant={variant}>
                  <strong>
                    <mark className={cls.mark} style={mark.style}>
                      {text}
                    </mark>
                  </strong>
                </Typography>
                {index === 0 && <Spacer size={isMobile ? 1 : 2} />}
              </React.Fragment>
            );
          })}
        </Container>
      </Box>
    </Box>
  );
};

Hero.displayName = 'Hero';

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
