import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Touchless = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 58.6 58.6" color="primary">
      <g>
        <path
          d="M30.9,56.5c-5.9,0-11.6-2.1-16.2-5.9l6.1-6.1c-0.1-0.1-0.2-0.2-0.3-0.3l-2.8-3.4l-6.4,6.5
			c-3.8-4.6-5.9-10.4-5.9-16.2C5.4,17,16.8,5.6,30.9,5.6c5.9,0,11.7,2.1,16.2,5.9L35.8,22.8c1.5,0,2.8,0.6,3.9,1.5
			c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.5,0.1l9.5-9.5c3.8,4.6,5.9,10.4,5.9,16.2C56.4,45.1,45,56.5,30.9,56.5 M30.9,0.6
			C14.2,0.6,0.5,14.2,0.5,31s13.7,30.4,30.4,30.4S61.3,47.7,61.3,31S47.6,0.6,30.9,0.6"
        />
        <path
          d="M30.9,56.5c-5.9,0-11.6-2.1-16.2-5.9l6.1-6.1c-0.1-0.1-0.2-0.2-0.3-0.3l-2.8-3.4l-6.4,6.5
			c-3.8-4.6-5.9-10.4-5.9-16.2C5.4,17,16.8,5.6,30.9,5.6c5.9,0,11.7,2.1,16.2,5.9L35.8,22.8c1.5,0,2.8,0.6,3.9,1.5
			c0.3-0.1,0.6-0.1,0.9-0.1c0.2,0,0.4,0,0.5,0.1l9.5-9.5c3.8,4.6,5.9,10.4,5.9,16.2C56.4,45.1,45,56.5,30.9,56.5z M30.9,0.6
			C14.2,0.6,0.5,14.2,0.5,31s13.7,30.4,30.4,30.4S61.3,47.7,61.3,31S47.6,0.6,30.9,0.6z"
        />
        <path
          d="M40.6,26.2c-0.6,0-1.1,0.1-1.6,0.4c-0.7-1.1-1.9-1.8-3.2-1.8c-0.5,0-1.1,0.1-1.6,0.4
			c-0.7-1.1-1.9-1.8-3.2-1.8c-0.4,0-0.7,0.1-1,0.2v-7.3c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-2.1,0-3.7,1.7-3.7,3.7v15
			L21.3,30c-1.3-1.3-3.3-1.5-4.8-0.4c-0.9,0.6-1.4,1.5-1.6,2.5c-0.2,1,0.1,2,0.8,2.9l6.6,8c2,2.5,5,4,8.3,4h4.1c2.6,0,4.9-1,6.9-2.8
			c1.8-1.8,2.8-4.3,2.8-6.9V30C44.2,27.9,42.6,26.2,40.6,26.2"
        />
        <path
          d="M40.6,26.2c-0.6,0-1.1,0.1-1.6,0.4c-0.7-1.1-1.9-1.8-3.2-1.8c-0.5,0-1.1,0.1-1.6,0.4
			c-0.7-1.1-1.9-1.8-3.2-1.8c-0.4,0-0.7,0.1-1,0.2v-7.3c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.6-1.1c-2.1,0-3.7,1.7-3.7,3.7v15
			L21.3,30c-1.3-1.3-3.3-1.5-4.8-0.4c-0.9,0.6-1.4,1.5-1.6,2.5c-0.2,1,0.1,2,0.8,2.9l6.6,8c2,2.5,5,4,8.3,4h4.1c2.6,0,4.9-1,6.9-2.8
			c1.8-1.8,2.8-4.3,2.8-6.9V30C44.2,27.9,42.6,26.2,40.6,26.2z"
        />
      </g>
    </SvgIcon>
  );
};

Touchless.displayName = 'Touchless';

Touchless.defaultProps = {};

export default Touchless;
