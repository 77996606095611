import queryString from 'query-string';

export default async (
  email,
  name = null,
  message = null,
  phone = null,
  extra = null
) => {
  if (window.gtag) {
    window.gtag('event', 'generate_lead', {
      event_category: 'Contact Form sent',
      event_label: 'engagement',
      value: 1,
    });
  }

  return fetch(
    'https://gdx1o47shg.execute-api.eu-central-1.amazonaws.com/prod/contact',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: queryString.stringify({ name, message, phone, extra, email }),
    }
  );
};
