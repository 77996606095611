import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Brain = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 70.8 70.8" color="primary">
      <g>
        <path
          d="M62.7,13.5c0.8,0.7,0.8,1.9,0.1,2.7l-7.5,7.4c-0.8,0.8-2.1,0.8-2.9,0.1s-0.8-1.9-0.1-2.7l7.5-7.4
			C60.6,12.8,61.9,12.8,62.7,13.5 M68.7,30.9c1.1,0,2,0.9,2,1.9c0,1.1-0.9,1.9-2,1.9H57.9c-1.1,0-2-0.9-2-1.9c0-1.1,0.9-1.9,2-1.9
			H68.7z M7.6,29.2c-1.1,0.1-2.3,0.2-3.4,0.3v8c1.1,0.1,2.3,0.2,3.4,0.4V29.2z M42.2,18.2c-5.9,3.1-12.1,5.5-18.4,7.5
			c-4.1,1.3-8.2,2.2-12.2,2.9v9.8c4.2,0.7,8.2,1.6,12.2,2.8c5.9,1.8,12.1,4.3,18.4,7.5V18.2z M55.1,42.9L55.1,42.9l7.6,7.5
			c0.8,0.8,0.8,2-0.1,2.7c-0.8,0.7-1.9,0.7-2.7,0.1l-0.2-0.1l-7.5-7.4c-0.8-0.8-0.8-2,0.1-2.7C53.1,42.3,54.3,42.3,55.1,42.9
			 M12.6,42.5l0.1,0.4c0.4,2.2,0.7,4.4,0.9,6.6c0.1,1.3,0.1,2.3,0.1,3.4c0,0.9-0.1,1.5-0.2,3.3c0,0.1,0,0.1,0,0.2
			c0.1,0.9,1.2,1.7,2.8,1.6c0.1,0,0.2,0,0.3,0c1.5-0.2,2.3-1.2,2.1-2c-0.4-1.8-0.5-2.4-0.7-3.2c-0.2-1-0.3-2-0.4-3.3
			c-0.1-1.9-0.2-3.7-0.1-5.6C15.9,43.1,14.3,42.8,12.6,42.5L12.6,42.5z M46.3,14.9V52c0,1.5-1.7,2.4-3,1.7
			c-7.2-3.9-14.1-6.8-20.7-8.8l-1-0.3c0,1.4,0,2.8,0.1,4.3c0.1,1.2,0.2,2.1,0.4,3c0.1,0.8,0.3,1.3,0.6,3c0.7,3.1-1.8,6.1-5.4,6.6
			c-0.2,0-0.5,0.1-0.7,0.1c-3.6,0.2-6.9-2-7.1-5.1c0-0.2,0-0.5,0-0.7c0.1-1.7,0.2-2.3,0.2-3.1c0-0.9,0-1.8-0.1-3
			c-0.2-2.4-0.5-4.8-1.1-7.1c-0.1-0.2-0.1-0.5,0-0.7c-2.1-0.3-4.3-0.5-6.5-0.7c-1.1-0.1-1.9-0.9-1.9-1.9V27.7c0-1,0.8-1.9,1.9-1.9
			c6.5-0.3,13.4-1.6,20.6-3.7c7.2-2.2,14.1-5.1,20.7-8.8C44.6,12.5,46.3,13.4,46.3,14.9"
        />
      </g>
    </SvgIcon>
  );
};

Brain.displayName = 'Brain';

Brain.defaultProps = {};

export default Brain;
