import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Persons = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 58.7 58.7" color="primary">
      <g>
        <path
          d="M29.2,55c-0.1,0.3-0.1,0.5,0,0.6s0.3,0.2,0.6,0.2h20.5c0.3,0,0.5-0.1,0.6-0.2s0.1-0.4,0-0.6
			c0-0.1-0.5-1.3-2-2.5C46.8,50.8,43.8,50,40,50s-6.8,0.9-8.9,2.5C29.7,53.7,29.2,54.9,29.2,55 M50.3,58.8H29.8
			c-1.2,0-2.3-0.5-3-1.4s-0.8-2.1-0.4-3.3c0.1-0.3,2.5-7,13.7-7s13.6,6.7,13.7,7c0.4,1.2,0.2,2.4-0.4,3.3
			C52.7,58.2,51.6,58.8,50.3,58.8"
        />
        <path
          d="M18.1,5.9c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S20.5,5.9,18.1,5.9 M18.1,17.7
			c-4,0-7.3-3.3-7.3-7.3s3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3S22.1,17.7,18.1,17.7"
        />
        <path
          d="M21,32.3H9.3c-1.2,0-2.3-0.5-3-1.4s-0.8-2.1-0.4-3.3C6,27.4,6.6,25.7,8.4,24c1.7-1.6,4.7-3.4,9.7-3.4
			c1.1,0,2.2,0.1,3.2,0.3c0.8,0.1,1.3,0.9,1.2,1.7s-0.9,1.3-1.7,1.2s-1.7-0.2-2.7-0.2c-7.7,0-9.4,5-9.4,5c-0.1,0.3-0.1,0.5,0,0.6
			s0.3,0.2,0.6,0.2H21c0.8,0,1.5,0.7,1.5,1.5S21.8,32.3,21,32.3L21,32.3z"
        />
        <path
          d="M9.3,20.6H6.4c-0.8,0-1.5-0.7-1.5-1.5V1.5C4.9,0.7,5.6,0,6.4,0h2.9c0.8,0,1.5,0.7,1.5,1.5S10.1,3,9.3,3H7.8
			v14.7h1.5c0.8,0,1.5,0.7,1.5,1.5C10.7,19.9,10.1,20.6,9.3,20.6"
        />
        <path
          d="M29.8,20.6h-2.9c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h1.5V3h-1.5c-0.8,0-1.5-0.7-1.5-1.5S26.1,0,26.9,0
			h2.9c0.8,0,1.5,0.7,1.5,1.5v17.6C31.3,19.9,30.6,20.6,29.8,20.6"
        />
        <path
          d="M40.1,29.4c-3.2,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9c3.2,0,5.9-2.6,5.9-5.9C45.9,32,43.3,29.4,40.1,29.4
			 M40.1,44.1c-4.9,0-8.8-4-8.8-8.8c0-4.8,4-8.8,8.8-8.8c4.9,0,8.8,4,8.8,8.8C48.9,40.1,44.9,44.1,40.1,44.1"
        />
        <path
          d="M53.3,47h-2.9c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h1.4V26.5h-1.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
			h2.9c0.8,0,1.5,0.7,1.5,1.5v20.5C54.8,46.4,54.1,47,53.3,47"
        />
        <path
          d="M29.8,47h-2.9c-0.8,0-1.5-0.7-1.5-1.5V25c0-0.8,0.7-1.5,1.5-1.5h2.9c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5
			h-1.5v17.6h1.5c0.8,0,1.5,0.7,1.5,1.5S30.6,47,29.8,47"
        />
      </g>
    </SvgIcon>
  );
};

Persons.displayName = 'Persons';

Persons.defaultProps = {};

export default Persons;
