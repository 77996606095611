import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Building = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 58.6 59.8" color="primary">
      <g>
        <path
          d="M34,20.1h4.7c0.4,0,0.6-0.3,0.6-0.6V15c0-0.3-0.3-0.6-0.6-0.6H34c-0.4,0-0.6,0.3-0.6,0.6v4.5
			C33.4,19.8,33.7,20.1,34,20.1"
        />
        <path
          d="M34,20.1h4.7c0.4,0,0.6-0.3,0.6-0.6V15c0-0.3-0.3-0.6-0.6-0.6H34c-0.4,0-0.6,0.3-0.6,0.6v4.5
			C33.4,19.8,33.7,20.1,34,20.1z"
        />
        <path
          d="M24.6,14.3h-4.7c-0.4,0-0.6,0.3-0.6,0.6v4.5c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5
			C25.2,14.6,24.9,14.3,24.6,14.3"
        />
        <path
          d="M24.6,14.3h-4.7c-0.4,0-0.6,0.3-0.6,0.6v4.5c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5
			C25.2,14.6,24.9,14.3,24.6,14.3z"
        />
        <path
          d="M34,31.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5c0-0.3-0.3-0.6-0.6-0.6H34c-0.4,0-0.6,0.3-0.6,0.6V31
			C33.4,31.4,33.7,31.6,34,31.6"
        />
        <path
          d="M34,31.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5c0-0.3-0.3-0.6-0.6-0.6H34c-0.4,0-0.6,0.3-0.6,0.6V31
			C33.4,31.4,33.7,31.6,34,31.6z"
        />
        <path
          d="M24.6,25.9h-4.7c-0.4,0-0.6,0.3-0.6,0.6V31c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5
			C25.2,26.1,24.9,25.9,24.6,25.9"
        />
        <path
          d="M24.6,25.9h-4.7c-0.4,0-0.6,0.3-0.6,0.6V31c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6v-4.5
			C25.2,26.1,24.9,25.9,24.6,25.9z"
        />
        <path
          d="M24.6,37.4h-4.7c-0.4,0-0.6,0.3-0.6,0.6v4.5c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6V38
			C25.2,37.7,24.9,37.4,24.6,37.4"
        />
        <path
          d="M24.6,37.4h-4.7c-0.4,0-0.6,0.3-0.6,0.6v4.5c0,0.3,0.3,0.6,0.6,0.6h4.7c0.4,0,0.6-0.3,0.6-0.6V38
			C25.2,37.7,24.9,37.4,24.6,37.4z"
        />
        <path
          d="M13.2,7.3h3h26.1h3v45.2h-5.7V39.7c0-0.4-0.4-0.8-0.8-0.8h-5.3c-0.4,0-0.8,0.4-0.8,0.8v12.8H13.2V7.3z
			 M51.5,52.5H50v-49c0-0.4-0.3-0.7-0.7-0.7h-6.1V1.3c0-0.4-0.3-0.7-0.7-0.7H16.3c-0.4,0-0.7,0.3-0.7,0.7v1.5H9.4
			C9,2.8,8.7,3.1,8.7,3.5v49H7.1c-0.4,0-0.7,0.3-0.7,0.7v5.3c0,0.4,0.3,0.7,0.7,0.7h44.5c0.4,0,0.7-0.3,0.7-0.7v-5.3
			C52.3,52.8,51.9,52.5,51.5,52.5"
        />
        <path
          d="M13.2,7.3h3h26.1h3v45.2h-5.7V39.7c0-0.4-0.4-0.8-0.8-0.8h-5.3c-0.4,0-0.8,0.4-0.8,0.8v12.8H13.2V7.3z
			 M51.5,52.5H50v-49c0-0.4-0.3-0.7-0.7-0.7h-6.1V1.3c0-0.4-0.3-0.7-0.7-0.7H16.3c-0.4,0-0.7,0.3-0.7,0.7v1.5H9.4
			C9,2.8,8.7,3.1,8.7,3.5v49H7.1c-0.4,0-0.7,0.3-0.7,0.7v5.3c0,0.4,0.3,0.7,0.7,0.7h44.5c0.4,0,0.7-0.3,0.7-0.7v-5.3
			C52.3,52.8,51.9,52.5,51.5,52.5z"
        />
      </g>
    </SvgIcon>
  );
};

Building.displayName = 'Building';

Building.defaultProps = {};

export default Building;
