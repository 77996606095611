import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const Spacer = ({ size, variant }) => {
  const theme = useTheme() || { spacing: (size) => size * 4 };

  return variant === 'row' ? (
    <Box
      width="100%"
      maxHeight={theme.spacing(size)}
      minHeight={theme.spacing(size)}
    />
  ) : (
    <Box
      minWidth={theme.spacing(size)}
      maxWidth={theme.spacing(size)}
      height="100%"
    />
  );
};

Spacer.displayName = 'Spacer';

Spacer.propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(['row', 'column']),
};

Spacer.defaultProps = {
  size: 1,
  variant: 'row',
};

export default Spacer;
