import React, { useCallback, useState } from 'react';

import {
  Typography,
  Box,
  TextField,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import contactRequest from '../utils/contactRequest';

import { useCurrentLanguage } from './LanguageProvider';
import Spacer from './Spacer';

const ContactForm = () => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      setSending(true);
      e.preventDefault();
      try {
        const res = await contactRequest(
          email,
          name,
          message,
          phone,
          'HEALTH DEVICE'
        );
        console.log(res);
      } catch (e) {
        console.log(e);
      } finally {
        setSending(false);
      }
    },
    [email, message, name, phone]
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const currentLang = useCurrentLanguage();

  return (
    <Box>
      <Typography variant="h4">
        <strong>
          {currentLang === 'de' ? 'Kontaktieren Sie uns' : 'Contact us'}!
        </strong>
        <Spacer size={2} />
        <form onSubmit={handleSubmit}>
          <Box
            style={{
              gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
              gridGap: theme.spacing(2),
            }}
            display="grid"
          >
            <Box
              style={{
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 1fr 1fr ',
                gridGap: theme.spacing(isMobile ? 1 : 0),
              }}
              display="grid"
              justifyContent="space-between"
            >
              <TextField
                style={{ alignSelf: 'start' }}
                id="name"
                label="Name (optional)"
                variant="outlined"
                placeholder="Name (optional)"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                style={{ alignSelf: 'center' }}
                required
                id="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="email"
              />
              <TextField
                style={{ alignSelf: 'end' }}
                id="telephone"
                label="Telefon (optional)"
                variant="outlined"
                type="phone"
                onChange={(event) => setPhone(event.target.value)}
              />
            </Box>
            <Box>
              <TextField
                style={{ width: '100%', height: '100%' }}
                id="message"
                label="Nachricht (optional)"
                multiline
                rows="8"
                variant="outlined"
                onChange={(event) => setMessage(event.target.value)}
              />
            </Box>
          </Box>
          <Spacer size={1} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={sending}
              size="large"
              variant="outlined"
              type="submit"
              fullWidth={isMobile}
              onClick={handleSubmit}
            >
              <strong>
                {currentLang === 'de' ? 'Nachricht Senden' : 'Send message'}
              </strong>
            </Button>
          </Box>
        </form>
      </Typography>
    </Box>
  );
};

ContactForm.displayName = 'ContactForm';

ContactForm.propTypes = {};

ContactForm.defaultProps = {};

export default ContactForm;
