import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, useMediaQuery } from '@material-ui/core';

import Spacer from './Spacer';
import icons from './Icons';
import { useTheme } from '@material-ui/styles';

const Feature = ({ title, text, icon }) => {
  const Icon = icons[icon];
  const theme = useTheme();
  return (
    <Box
      style={{
        gridTemplateColumns: '28px 1fr',
        gridTemplateRows: '40px auto',
        alignItems: 'center',
        gridGap: theme.spacing(1),
      }}
      display="grid"
    >
      <Icon />
      <Typography variant="h5">
        <strong>{title}</strong>
      </Typography>
      <Spacer size={1} />
      <Typography style={{alignSelf: 'start'}} align="justify">{text}</Typography>
    </Box>
  );
};

Feature.displayName = 'Feature';

Feature.propTypes = {};

Feature.defaultProps = {};

export default Feature;
