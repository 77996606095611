import React from 'react';
import PropTypes from 'prop-types';

import { Container, useMediaQuery } from '@material-ui/core';

import Layout from '../components/Layout';

import Hero from '../components/Hero';
import FeatureList from '../components/FeatureList';
import Spacer from '../components/Spacer';
import ContactForm from '../components/ContactForm';

const Homepage = ({ seo }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const spacerSize = isMobile ? 4 : 6;

  return (
    <Layout seo={seo}>
      <Hero />
      <Spacer size={spacerSize} />
      <Container maxWidth="md">
        <FeatureList />
        <Spacer size={spacerSize} />
        <ContactForm />
      </Container>
      <Spacer size={spacerSize} />
    </Layout>
  );
};

Homepage.displayName = 'Homepage';

Homepage.propTypes = {
  seo: PropTypes.shape({
    meta: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        name: PropTypes.shape.isRequired,
      })
    ),
  }),
};

Homepage.defaultProps = {};

export default Homepage;
