import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useMediaQuery } from '@material-ui/core';

const Brain = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const fontSize = isMobile ? 'small' : 'default';

  return (
    <SvgIcon fontSize={fontSize} viewBox="0 0 58.6 58.6" color="primary">
      <g>
        <path
          d="M11.8,36.8c3.9,5.4,6.2,11.9,6.5,18.5h22.6v-8.6c0-0.9,0.7-1.6,1.6-1.6h3.8c1,0,1.7-0.8,1.7-1.8v-3.8
			c0-0.9,0.7-1.6,1.6-1.6h3.5L48.3,30c-0.2-0.3-0.2-0.6-0.2-0.9v-5c0-11.6-9-21-20.1-21s-20.1,9.4-20.1,21
			C7.8,28.8,9.2,33.1,11.8,36.8 M27.9,0c12.8,0,23.2,10.9,23.2,24.2v4.6l5,8.2c0.5,0.9,0.6,1.9,0.1,2.8c-0.5,0.9-1.4,1.4-2.3,1.4
			h-2.8v2.1c0,2.8-2.2,5.1-4.9,5.1H44V57c0,0.9-0.7,1.6-1.6,1.6H16.9c-0.9,0-1.6-0.7-1.6-1.6c0-6.5-2.1-12.9-6-18.2
			c-3.1-4.2-4.7-9.3-4.7-14.6C4.7,10.8,15.1,0,27.9,0"
        />
      </g>
      <g>
        <path
          d="M15.5,25.2c0.6,0.5,1.1,1.1,1.5,1.7c2.3-0.6,3-1.7,3-1.8c0.4-0.8,1.3-1,2.1-0.6s1.1,1.4,0.7,2.2
			C22.7,27,21.6,29,18.1,30c0,0.3,0.1,0.7,0.1,1c0,1.7,0.4,3.2,1.1,4.3c0.6,1,1.4,1.5,2.4,1.5h0.1c2.6-0.1,2.6-0.1,2.6-3.1
			c0-0.4,0-0.8,0-1.2v-0.1c-0.1-0.7-0.4-2.8,0.9-4.4c1-1.2,2.5-1.8,4.7-1.8c1.1,0,2.1-0.4,2.9-1.2c0.9-0.9,2.3-1.1,3.4-0.5
			c1.7,0.9,3.8,0.4,5.1-1c0.8-1,1.2-2.2,1.1-3.5c-0.2-1.9-1.7-3.6-3.6-3.9c-0.3,0-0.6-0.1-0.8,0c-0.8,1.3-2.8,3.2-7.8,4.1
			c-0.1,0-0.2,0-0.3,0c-0.7,0-1.4-0.6-1.5-1.4c-0.2-0.9,0.4-1.7,1.3-1.9c4.3-0.8,5.5-2.3,5.7-2.7c-0.1-2.4-2-4.3-4.3-4.3
			c-0.7,0-1.3,0.1-1.9,0.5c-1.6,0.8-3.6,0.9-5.4,0.3c-0.4-0.1-0.9-0.2-1.3-0.2c-2.4,0-4.3,2-4.3,4.5c0,0.2,0,0.4,0.1,0.6
			c0.1,0.8-0.4,1.6-1.1,1.8c-1.9,0.5-3.2,2.3-3.2,4.3C13.9,23.1,14.5,24.4,15.5,25.2 M15.1,14.8c0.1-4.2,3.4-7.5,7.4-7.5
			c0.8,0,1.5,0.1,2.3,0.4C25.9,8.1,27,8,27.9,7.6c1-0.5,2.1-0.8,3.2-0.8c3.5,0,6.5,2.6,7.2,6.1c0.3,0,0.6,0,0.9,0.1
			c3.2,0.5,5.8,3.4,6.2,6.8c0.2,2.2-0.4,4.4-1.8,6c-1.4,1.6-3.4,2.6-5.5,2.6c-1.1,0-2.2-0.3-3.2-0.8c-1.4,1.3-3.1,2-5,2
			c-1.1,0-1.9,0.2-2.2,0.6c-0.4,0.5-0.3,1.4-0.2,1.8c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2c0.1,3.7-0.3,6.2-5.5,6.4
			c-0.1,0-0.2,0-0.3,0c-2,0-3.8-1-5-2.9c-1-1.6-1.6-3.8-1.6-6.1c0-1.3-0.5-2.5-1.5-3.2c-1.8-1.5-2.8-3.7-2.8-6
			C10.8,18.7,12.5,16,15.1,14.8"
        />
      </g>
    </SvgIcon>
  );
};

Brain.displayName = 'Brain';

Brain.defaultProps = {};

export default Brain;
